<template>
  <v-container>
    <h1>Gestion de l'open de peinture</h1>
    <v-form ref="updateForm">
      <h2>Texte de présentation</h2>
      <wysiwyg v-model="text"/>
      <h2>Url document inscriptions</h2>
      <v-text-field v-model="url" :label="$t('Lien')"/>
      <v-btn color="primary" @click="files.push({title:'',text:''})">Ajouter fichier</v-btn>
      <v-btn color="primary" @click="save">
        <v-icon>mdi-briefcase-download</v-icon>
        Sauver
      </v-btn>
    </v-form>
    <Loading :visible="isLoading"/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import Loading from '../../components/Loading';

const servicePrefix = 'GlobalParams';

export default {
  name: 'InscriptionExposants',
  servicePrefix,
  components: {
    Loading,
  },
  data() {
    return {
      item: null,
      url: null,
      text: null,

    }
  },
  beforeMount() {
    this.isLoading = false;
    this.retrieve(decodeURIComponent(this.$route.name))
        .then(() => {
          this.item = this.find(decodeURIComponent(this.$route.name));
          this.url = this.item.value.url;
          this.text = this.item.value.text;
        })
        .catch(e => {
          console.error(e);
        })
  },
  computed: {
    ...mapFields('globalParam', {
      isLoading: 'isLoading'
    }),
    ...mapGetters({
      find: 'globalParam/find',
    }),
  },
  methods: {
    ...mapActions('globalParam', {
      retrieve: 'load',
      update: 'update'
    })
    ,
    save() {
      this.update(Object.assign(this.item, {
            'value':
                {
                  'text': this.text,
                  'url': this.url
                }
          }
      ));
    }
  }
  ,
  watch: {}
}
</script>
